import { inject, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import userService from 'services/api/userService';
import { TranslationService } from 'services/translationService';

@inject(DialogController,TranslationService)
export class FeedbackDialog {
  constructor(_DialogController, _TranslationService) {
    this.dialogController = _DialogController;

    this.userService = userService;
    this.translationService = _TranslationService;

    this.feedback = null;
    this.loading = false;
    this.sent = false;
    this.msg = null;

    this.rating = 0;
  }

  @computedFrom('feedback', 'rating')
  get canSend() {
    return (this.feedback && this.feedback.length > 10) || (this.rating > 0)
  }

  activate(user) {
    this.user = user;

    if (!this.user) {
      this.loading = true;
      this.userService.getSelf().then(user => {
        this.user = user;
        this.loading = false;
      });
    }
  }

  onSend() {
    if(this.canSend) {
      this.loading = true;
      this.userService.sendFeedback(this.rating, this.feedback)
      .then(() => {
        this.msg = this.translationService.getCap('thankyou_feedback')
        this.loading = false;
        this.sent = true;
      })
      .catch(err => {
        this.msg = err.message;
        this.loading = false;

      })
    }
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
