import { bindable } from 'aurelia-framework';
import uiService from 'services/uiService';

import userService from 'services/api/userService';

import './impersonation.scss';

export class Impersonation {

  @bindable onUserSelected;

  constructor() {
    this.uiService = uiService;
    this.userService = userService;

    this.resellers = [];
    this.accounts = [];
    this.users = [];

    this.loadingResellers = false;
    this.loadingAccounts = false;
    this.loadinUsers = false;

    this.selfUser = null;    
    this.selfAccountId = null;
    this.selfUserId = null;

    this.onResellerListSelected = this.onResellerListSelected.bind(this);
    this.onAccountListSelected = this.onAccountListSelected.bind(this);
    this.onUserListSelected = this.onUserListSelected.bind(this);

    this.onToggleResellerList = this.onToggleResellerList.bind(this);

    this.showResellers = false;
    this.resellerListActive = false;
  }

  attached() {    
    let impersonateAdmin = this.uiService.impersonation && this.uiService.impersonation.isAdmin;
    this.showResellers = this.userService.hasRole('admin') || impersonateAdmin;
    this.resellerListActive = this.userService.hasRole('admin');
    if (this.showResellers) {
      this.loadResellers();
    }
    this.loadAccounts();

    this.userService.getSelf().then(me => this.selfUser = me);
  }

  onToggleResellerList() {
    this.resellerListActive = !this.resellerListActive;
  }

  loadResellers() {
    this.loadingResellers = true;
    this.userService.getResellers().then(resellers => {
      this.resellers = resellers;
      this.selectedReseller = null;
      this.loadingResellers = false;
    });
  }

  loadAccounts(resellerId) {
    this.loadingAccounts = true;
    this.userService.getAccounts(null, resellerId).then(accounts => {
      this.accounts = accounts;
      this.selectedAccount = null;
      //auto-select account on the list
      this.selfAccountId = this.selfUser ? this.selfUser.accountId : null;
      if (this.selfAccountId) {
        this.loadUsers(this.selfAccountId);
      }
      this.loadingAccounts = false;
    })
  }

  loadUsers(accountId) {
    this.loadingUsers = true;
    this.userService.getUsers(null,accountId).then(users => {
      let meId = this.selfUser ? this.selfUser.id : null;

      //disable current 
      if (meId) {
        let me = users.find(x=> x.id === meId);
        if(me) me.disabled = true;
      }
    
      //auto-select user on the list
      this.users = users;
      // this.selfUserId = meId;
      this.loadingUsers = false;
    })
  }

  onResellerListSelected([reseller]) {
    if (reseller && this.selectedResellerId !== reseller.id) {
      this.selectedResellerId = reseller.id;
      this.loadAccounts(this.selectedResellerId);
      this.onToggleResellerList();
    }
  }

  onAccountListSelected([account]) {
    if (account && account.id !== this.selectedAccontId) {
      this.selectedAccontId = account.id;
      this.loadUsers(this.selectedAccontId);
    }
  }

  onUserListSelected([user]) {
    if (user && this.onUserSelected) {
      let sameUser = user.username === (this.uiService.impersonation ? this.uiService.impersonation.username : null);
      this.onUserSelected(sameUser ? null : user);
    }
  }

}
