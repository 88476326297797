/** @format */

import { bindable } from 'aurelia-framework';
import './image-picker.scss';
export class ImagePicker {
  @bindable file;
  @bindable text;
  @bindable onChange;

  constructor() {
    this.onDropTarget = this.onDropTarget.bind(this);
    this.onFileInputChange = this.onFileInputChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onDropClick() {
    if (this.fileInputRef) {
      this.fileInputRef.click();
    }
  }

  onDropTarget(e) {
    const file = (e.files || [])[0];
    this.setFile(file);
  }

  onFileInputChange(e) {
    const file = (e.target && (e.target.files || [])[0]) || null;
    this.setFile(file);

    //clear
    e.target.value = '';
  }

  onDelete(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setFile(null);
  }

  setFile(file) {
    if (file) {
      this.errorFormat = (file.type && !file.type.match('image/*'));
      this.errorSize = file.size > (1024 * 20) ? file.size : null;

      if (this.errorFormat || this.errorSize) {
        return;
      }
    }

    this.file = file;
    if (this.onChange) {
      this.onChange(file);
    }
  }
}
